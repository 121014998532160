import React from 'react';
import styled from 'styled-components';

// client images
import appleLogo from 'src/images/clients/apple.png';
import beyonceLogo from 'src/images/clients/beyonce.png';
import unileverLogo from 'src/images/clients/unilever.png';
import otherLogo from 'src/images/clients/other.png';
import vliscoLogo from 'src/images/clients/vlisco.png';
import tonyLogo from 'src/images/clients/tony.png';
import conanLogo from 'src/images/clients/conan.png';

const imageArray = [
  {
    url: appleLogo,
  },

  {
    url: beyonceLogo,
  },

  {
    url: unileverLogo,
  },

  {
    url: otherLogo,
  },

  {
    url: vliscoLogo,
  },
  {
    url: tonyLogo,
  },

  {
    url: conanLogo,
  },
];

const Clients = () => {
  return (
    <StyledClientsContainer>
      <div className="container-lg">
        <h3>Brands we’ve worked on projects with</h3>
      </div>
      <div className="images-container">
        {imageArray.map((image, i) => {
          return <img src={image.url} key={i} />;
        })}
      </div>
    </StyledClientsContainer>
  );
};

const StyledClientsContainer = styled.div`
  margin: 5rem 0;

  .images-container {
    padding: 3% 0;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;

    img {
      height: 60px;
      width: 220px;
      object-fit: contain;
      transform: translate3d(0, 0, 0);
      animation: moveSlideshow 10s linear infinite;
      margin: 0 60px;
    }
  }

  .marquee {
    @keyframes moveSlideshow {
      100% {
        transform: translatex(-300%);
      }
    }
  }
  h3 {
    font-size: 1.8rem;
    font-weight: 200;
    margin-bottom: 2rem;
    text-align: center;
  }
`;

export default Clients;
