import React from 'react';
import SEO from '../components/seo';
import styled from 'styled-components';
import { HomePageHeader } from 'src/components/PageHeaders/';
import Modal from 'react-modal';
import { Clients } from '../components/HomePage';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { Footer, Banner, PhotographyBackgroundImage } from '../components/common';
import { Link } from 'gatsby';

// images
import BIK from 'src/images/backgrounds/black-is-king.jpg';
import Apple from 'src/images/backgrounds/apple.jpg';
import Vlisco from 'src/images/cinematography-images/vlisco.png';

const photographyList = [
  {
    text: 'AVI',
    imageName: 'avi',
    link: '/portfolio/photography/events/coperate-events',
  },

  {
    text: 'Garden Part',
    imageName: 'garden-party',
    link: 'portfolio/photography/events/garden-party',
  },

  {
    text: 'IMF',
    imageName: 'imf',
    link: '/portfolio/photography/documentary/imf',
  },

  {
    text: 'Bead The Change',
    imageName: 'bedthechange',
    link: '/portfolio/photography/documentary/bead-the-change',
  },

  {
    text: 'Distant Relatives',
    imageName: 'distantRelatives',
    link: '/portfolio/photography/documentary/distant-relative',
  },
];

const PhotgridItem = ({ text, imageName, link, selected }) => {
  return (
    <Link to={link}>
      <PhotographyItem key={text}>
        <PhotographyBackgroundImage name={imageName}>
          <p className="header">{text}</p>
        </PhotographyBackgroundImage>
      </PhotographyItem>
    </Link>
  );
};

const PhotographyMenu = (list, selected) =>
  list.map((el) => {
    const { text, imageName, link } = el;
    return (
      <PhotgridItem link={link} imageName={imageName} text={text} key={text} selected={selected} />
    );
  });

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '70%',
  },
};
const IndexPage = () => {
  const [showModal, toggleModal] = React.useReducer((toggled) => !toggled, false);
  const [selectedVideo, setSelectedVideo] = React.useState('');

  const menu = PhotographyMenu(photographyList);

  return (
    <>
      <SEO title="Home" />
      <HomePageHeader />

      <StyledSection className={'container'} id="home-section-section">
        <h5 className="heading header">
          We’re a team of talented individuals constantly collaborating to create the best
          photography and cinematography content for brands from a strong storytelling perspective.
        </h5>
      </StyledSection>

      <CinematographySection className="container">
        <div
          className="header"
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <h1>Cinematography</h1>
        </div>

        <div className="image-container">
          <div
            className="left"
            onClick={() => {
              setSelectedVideo('https://www.youtube.com/embed/69MO7yU0d70?autoplay=1');
              toggleModal();
            }}
          >
            <img src={BIK} />
          </div>

          <div className="right">
            <div
              className="image-wrapper"
              onClick={() => {
                setSelectedVideo('https://player.vimeo.com/video/399218897?title=0&byline=0');
                toggleModal();
              }}
            >
              <img src={Apple} style={{ width: '100%', height: '100%', cursor: 'pointer' }} />
            </div>
            <div
              className="image-wrapper"
              onClick={() => {
                setSelectedVideo('https://player.vimeo.com/video/375398495?title=0&byline=0');
                toggleModal();
              }}
            >
              <img src={Vlisco} style={{ width: '100%', height: '100%' }} />
            </div>
          </div>
        </div>
      </CinematographySection>

      <PhotographySection>
        <div className="container">
          <h1
            className="header"
            style={{
              marginBottom: '20px',
            }}
          >
            Photography
          </h1>
        </div>

        <div className="flow-container">
          <ScrollMenu
            data={menu}
            // arrowLeft={ArrowLeft}
            // arrowRight={ArrowRight}
            // selected={selected}
            // onSelect={this.onSelect}
          />
        </div>
      </PhotographySection>

      <Clients />

      <Banner />
      <Footer />

      <Modal
        isOpen={showModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={toggleModal}
        style={customStyles}
      >
        <iframe
          id="ytplayer"
          type="text/html"
          style={{
            width: '100%',
            height: '100%',
          }}
          src={selectedVideo}
          frameborder="0"
        ></iframe>
      </Modal>
    </>
  );
};

export default IndexPage;

const CinematographySection = styled.div`
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0;

  .image-container {
    width: 60%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .left {
      height: 100%;
      width: 50%;
      cursor: pointer;
      transition: transform 0.2s;

      :hover {
        transform: scale(1.05);
        z-index: 9999999999999;
      }

      img {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
    }

    .right {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .image-wrapper {
        height: 50%;
        cursor: pointer;
        transition: transform 0.2s;

        :hover {
          transform: scale(1.05);
          z-index: 9999999999999;
        }
      }
    }
  }
`;

const StyledSection = styled.section`
  margin-top: 100px;
  margin-bottom: 100px;

  .heading {
    width: 50%;
    font-size: 32px;
    font-weight: 400;

    @media only screen and (max-width: 600px) {
      width: 100%;
      font-size: 25px;
    }
  }

  .section-2 {
    margin-top: 150px;
    display: flex;
    align-items: center;
    flex: 1;

    img {
      height: 100%;
      width: 100%;
    }

    .left {
      margin-right: 20px;
      height: 100vh;
      flex: 0.6;
      display: flex;
      justify-content: flex-start;
      height: 1141px;
      width: 689px;
      margin-right: auto;
    }

    .right {
      flex: 0.4;
      padding: 50px 100px;
      display: flex;
      flex-direction: column;

      h5 {
        font-size: 25px;
        font-weight: 400;
      }

      p {
        padding: 30px 0 70px 0;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.greyPrimary};
      }

      .image-grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        img {
          height: 200px;
          border-radius: 5px;
          object-fit: contain;
          margin-top: 10px;
        }
      }
    }
  }
`;

const PhotographySection = styled.div`
  min-height: 80vh;
  margin-top: 10rem;
  padding-top: 5rem;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .subtext {
    margin-bottom: 4rem;
  }
`;

const PhotographyItem = styled.div`
  width: 350px;
  height: 450px;
  margin: 10px;
  border-radius: 4px;
  transition: transform 0.4s;
  cursor: pointer;

  :first-child {
    margin-left: 0;
  }

  :hover {
    transform: scale(1.05);
    z-index: 9999999999999;
  }

  p {
    color: white;
    padding: 20px;
    margin-top: auto;
  }
`;
