import React from 'react';
import styled from 'styled-components';
import { Image } from '../common';

const Testimonials = () => (
  <StyledContainer>
    <div className="count">01 / 04</div>
    <Testimonial />
    <div className="footer">Testimonials</div>

    {/* <button type="button">Next</button> */}
  </StyledContainer>
);

const Testimonial = () => {
  return (
    <StyledTestimonal>
      <div className="left">
        <Image name="profile" width="100%" />
        <div className="name">Casprine Assempah</div>
        <div className="company">Apple</div>
      </div>

      <div className="right">
        Professionals in their craft! All products were super amazing with strong attention to
        details, comps. and overall vibe
      </div>
    </StyledTestimonal>
  );
};

const StyledTestimonal = styled.div`
  display: flex;
  margin: auto 0;
  align-items: center;

  .left {
    flex-basis: 0.3;
    img {
      width: 40px;
      height: 40px;
      /* border-radius: 50%; */
      border: 2px solid white;
      margin-bottom: 10px;
    }

    .name {
      margin: 10px 0;
    }

    .company {
      color: ${({ theme }) => theme.colors.greyPrimary};
    }
  }

  .right {
    flex: 0.7;
    margin-left: auto;
    font-size: 25px;
  }
`;

const StyledContainer = styled.div`
  background: ${({ theme }) => theme.colors.darkBlueLighter2x};
  color: white;
  height: 70vh;
  padding: 4rem 10rem;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10rem;

  .count {
    margin-left: auto;
  }

  button {
    background: ${({ theme }) => theme.colors.goldPrimary};
    color: black;
    width: 200px;
    font-size: 1rem;
    height: 55px;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 0;
    font-weight: 500;
    margin-bottom: -25px;
    font-family: 'Sectra' !important;
  }

  .footer {
    color: white;
  }
`;

export default Testimonials;
